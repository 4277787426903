import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, MainContent, Paragph } from "@components/layout/style"
import { MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"

const Endodoncja:React.FC = ({data}) => {
  return (
  <Layout
    data={data}
    prop={{
      canonicalUrl: 'https://www.mediroot.pl/oferta/endodoncja-wagrowiec/',
      title: 'Endodoncja Wągrowiec | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
    }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Endodoncja Wągrowiec, leczenie kanałowe"
        layout="fullWidth"/>
    </StyledHero>
    <MainContent>
      <MainContainer>
        <RightContainer>
          <H1>
            ENDODONCJA
          </H1>

          <Paragph>

          Dział stomatologii zachowawczej zajmujący się rozpoznawaniem i leczeniem chorób miazgi zęba oraz rozpoznawaniem i leczeniem chorób tkanek okołowierzchołkowych. Podstawową metodą leczenia jest w tym przypadku leczenie kanałowe. Posiadamy zaawansowaną aparaturę, dzięki czemu możemy naszym Pacjentom zaproponować nowoczesną endodoncję.

  Leczenie endodontyczne przeprowadzane jest Pacjentom, którzy posiadają bardzo głębokie ubytki próchnicowe z cechami zapalenia miazgi, a także w przypadku martwicy miazgi, po złamaniu lub zwichnięciu zęba, kończąc na wyraźnych zaleceniach protetyka przed wykonaniem korony lub mostu.
  Warunkiem sukcesu w jego leczeniu jest dokładne opracowanie kanałów przy użyciu narzędzi maszynowych/ręcznych z dokładnym pomiarem ich długości endometrem, obfite płukanie środkami dezynfekującymi,a następnie szczelne wypełnienie gutaperką. Efekt leczenia w postaci cofających się zmian pod zębem widać na zdjęciu RTG .

          </Paragph>

          <H3>
          PYTANIA
          </H3>

          <Paragph style={{
            textIndent:0
          }}>
            <Question>
            Czy leczenie endodontyczne jest bolesne?
            </Question>
            <Response>
            Obecnie dzięki  znieczuleniu w trakcie leczenia kanałowego można przeprowadzić całkowicie bezboleśnie.
            </Response>


            <Question>
            Jaka jest wytrzymałość zęba po leczeniu kanałowym?
            </Question>
            <Response>
            Zęby leczone endodontycznie przeważnie mają dość duże ubytki własnych tkanek, w celu wzmocnienia ich struktury w większości przypadków wskazane jest wykonanie korony protetycznej na wkładzie koronowo-korzeniowym – taka odbudowa zapewnia pełną wytrzymałość porównywalną z wytrzymałością własnego zdrowego zęba przed leczeniem.
            </Response>


            <Question>
            Jaka jest alternatywa dla leczenia kanałowego?
            </Question>
            <Response>
            Niestety alternatywą jest tylko usunięcie zęba, pozostawienie nieleczonego zęba może zakończyć się poważnymi powikłaniami miejscowymi  np. zapaleniem kości lub jej resorbcją jak równie ogólnoustrojowymi.
            </Response>
          </Paragph>


        </RightContainer>
      </MainContainer>
    </MainContent>
  </Layout>);
};

export default Endodoncja;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
